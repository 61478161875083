import React from 'react';
import './LegalPages.css'; // We'll create a shared CSS file for legal pages

function TermsOfService() {
  return (
    <div className="legal-container">
      <div className="legal-hero">
        <h1>Terms of Service</h1>
        <p className="last-updated">Last updated: March 18, 2024</p>
      </div>

      <div className="legal-content">
        <div className="legal-nav">
          <div className="nav-header">Contents</div>
          <ul>
            <li><a href="#acceptance">1. Acceptance of Terms</a></li>
            <li><a href="#access">2. Access and Use</a></li>
            <li><a href="#accounts">3. User Accounts</a></li>
            <li><a href="#content">4. User Content</a></li>
            <li><a href="#purchases">5. Purchases</a></li>
            <li><a href="#termination">6. Termination</a></li>
            <li><a href="#liability">7. Limitation of Liability</a></li>
            <li><a href="#contact">8. Contact Us</a></li>
          </ul>
        </div>

        <div className="legal-sections">
          <section id="acceptance">
            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using MasterMelon, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree with these terms, please do not use our services.</p>
          </section>

          <section id="access">
            <h2>2. Access and Use</h2>
            <div className="info-grid">
              <div className="info-card">
                <i className="fas fa-check-circle"></i>
                <h3>Permitted Use</h3>
                <p>Access for personal, non-commercial use</p>
              </div>
              <div className="info-card">
                <i className="fas fa-ban"></i>
                <h3>Prohibited Use</h3>
                <p>No unauthorized access or system interference</p>
              </div>
              <div className="info-card">
                <i className="fas fa-shield-alt"></i>
                <h3>Security</h3>
                <p>Maintain account security and confidentiality</p>
              </div>
            </div>
          </section>

          <section id="accounts">
            <h2>3. User Accounts</h2>
            <ul className="legal-list">
              <li>
                <i className="fas fa-user"></i>
                <span>You must provide accurate and complete information when creating an account</span>
              </li>
              <li>
                <i className="fas fa-lock"></i>
                <span>You are responsible for maintaining the security of your account</span>
              </li>
              <li>
                <i className="fas fa-bell"></i>
                <span>Notify us immediately of any unauthorized access</span>
              </li>
            </ul>
          </section>

          <section id="content">
            <h2>4. User Content</h2>
            <p>You retain ownership of content you post, but grant us a license to use, modify, and display it. You are responsible for ensuring your content doesn't violate any laws or rights.</p>
          </section>

          <section id="purchases">
            <h2>5. Purchases</h2>
            <div className="info-grid">
              <div className="info-card">
                <i className="fas fa-shopping-cart"></i>
                <h3>Payment</h3>
                <p>Secure payment processing through trusted providers</p>
              </div>
              <div className="info-card">
                <i className="fas fa-exchange-alt"></i>
                <h3>Refunds</h3>
                <p>Subject to our refund policy</p>
              </div>
            </div>
          </section>

          <section id="termination">
            <h2>6. Termination</h2>
            <p>We reserve the right to terminate or suspend access to our services, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
          </section>

          <section id="liability">
            <h2>7. Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>
          </section>

          <section id="contact">
            <h2>8. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us:</p>
            <div className="contact-info">
              <div className="contact-item">
                <i className="fas fa-envelope"></i>
                <p>Email: info@mastermelon.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;


/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  firestore, 
  auth, 
  collection, 
  query, 
  orderBy, 
  limit, 
  getDocs, 
  getDoc, 
  doc, 
  collectionGroup,
  Timestamp,
  where
} from '../../services/firebase';
import './RecentActivity.css';

function RecentActivity() {
  const [recentPosts, setRecentPosts] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchRecentPostsAndTopCategories = async () => {
      try {
        setLoading(true);
        const postsQuery = query(
          collectionGroup(firestore, 'posts'), 
          orderBy('lastActivityAt', 'desc'), 
          limit(5)
        );
        
        const querySnapshot = await getDocs(postsQuery);
        
        const posts = await Promise.all(querySnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          const categoryId = postDoc.ref.parent.parent.id;

          // Fetch author name
          let authorName = 'Unknown';
          if (postData.authorId) {
            const userSnap = await getDoc(doc(firestore, 'users', postData.authorId));
            if (userSnap.exists()) {
              authorName = userSnap.data().displayName || userSnap.data().email || 'Unknown';
            }
          }

          // Fetch category name
          let categoryName = 'Unknown Category';
          const categorySnap = await getDoc(doc(firestore, 'categories', categoryId));
          if (categorySnap.exists()) {
            categoryName = categorySnap.data().name;
          }

          // Fetch reply count
          const repliesSnapshot = await getDocs(collection(postDoc.ref, 'replies'));
          const replyCount = repliesSnapshot.size;

          return {
            id: postDoc.id,
            title: postData.title || 'Untitled',
            authorName,
            categoryId,
            categoryName,
            replyCount,
            createdAt: postData.createdAt?.toDate().toLocaleString() || 'Unknown date',
            lastActivityAt: postData.lastActivityAt?.toDate().toLocaleString() || 'Unknown date'
          };
        }));

        setRecentPosts(posts);

        // Fetch top categories
        const twentyFourHoursAgo = Timestamp.fromDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));

        const categoryPromises = categoriesSnapshot.docs.map(async (categoryDoc) => {
          const recentPostsSnapshot = await getDocs(
            query(
              collection(categoryDoc.ref, 'posts'),
              where('createdAt', '>=', twentyFourHoursAgo)
            )
          );

          return {
            id: categoryDoc.id,
            name: categoryDoc.data().name,
            count: recentPostsSnapshot.size
          };
        });

        const categoriesWithCounts = await Promise.all(categoryPromises);
        setTopCategories(
          categoriesWithCounts
            .sort((a, b) => b.count - a.count)
            .slice(0, 5)
        );

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchRecentPostsAndTopCategories();
    
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="recent-activity">
      <div className="recent-posts">
        <h2>Recent Activity</h2>
        <div className="posts-list">
          {recentPosts.map(post => (
            <div key={post.id} className="post-card">
              <h3>
                <Link to={`/category/${post.categoryId}/post/${post.id}`}>
                  {post.title}
                </Link>
              </h3>
              <div className="post-meta">
                <span>By {post.authorName}</span>
                <span>in {post.categoryName}</span>
                <span>{post.replyCount} replies</span>
              </div>
              <div className="post-timestamps">
                <span>Posted: {post.createdAt}</span>
                <span>Last activity: {post.lastActivityAt}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="sidebar">
        {currentUser ? (
          <div className="sidebar-section">
            <Link to="/create-post" className="create-post-btn">Create Post</Link>
          </div>
        ) : (
          <div className="sidebar-section">
            <h3>Join the Discussion</h3>
            <p>Sign in to participate in discussions</p>
            <Link to="/login" className="login-btn">Sign In</Link>
          </div>
        )}

        <div className="sidebar-section">
          <h3>Top Categories</h3>
          <ul className="top-categories">
            {topCategories.map(category => (
              <li key={category.id}>
                <Link to={`/category/${category.id}`}>
                  {category.name} ({category.count})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RecentActivity;

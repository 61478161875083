import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  firestore, 
  auth, 
  doc, 
  getDoc, 
  deleteDoc, 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  onSnapshot, 
  where,
  getDocs,
  updateDoc 
} from '../../services/firebase';
import './Post.css';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAnalytics } from '../../hooks/useAnalytics';

function Post() {
  const { categoryId, postId } = useParams();
  const [post, setPost] = useState(null);
  const [author, setAuthor] = useState(null);
  const [category, setCategory] = useState(null);
  const [replies, setReplies] = useState([]);
  const [newReply, setNewReply] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logCustomEvent } = useAnalytics();

  useEffect(() => {
    const fetchPostAndRelatedData = async () => {
      try {
        // Fetch category
        const categoryRef = doc(firestore, 'categories', categoryId);
        const categoryDoc = await getDoc(categoryRef);
        if (categoryDoc.exists()) {
          setCategory({ id: categoryDoc.id, ...categoryDoc.data() });
        } else {
          setError("Category not found");
          return;
        }

        // Fetch post
        const postRef = doc(firestore, 'categories', categoryId, 'posts', postId);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists()) {
          const postData = postDoc.data();
          setPost({ 
            id: postDoc.id, 
            ...postData,
            hashtags: postData.hashtags || [] // Ensure hashtags are included
          });

          // Fetch author
          if (postData.authorId) {
            const authorRef = doc(firestore, 'users', postData.authorId);
            const authorDoc = await getDoc(authorRef);
            if (authorDoc.exists()) {
              setAuthor(authorDoc.data());
            }
          }
        } else {
          setError("Post not found");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load post. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostAndRelatedData();

    // Set up real-time listener for replies
    const repliesRef = collection(firestore, 'categories', categoryId, 'posts', postId, 'replies');
    const q = query(repliesRef, orderBy('createdAt', 'asc'));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const fetchedReplies = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
        const replyData = docSnapshot.data();
        let authorData = null;

        if (replyData.authorId) {
          // Check if authorId looks like an email
          const isEmail = replyData.authorId.includes('@');
          
          if (isEmail) {
            // Search for user by email
            const usersRef = collection(firestore, 'users');
            const q = query(usersRef, where('email', '==', replyData.authorId));
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
              const userDoc = querySnapshot.docs[0];
              authorData = { id: userDoc.id, ...userDoc.data() };
              
              // Update the reply with the correct authorId
              const replyRef = doc(firestore, 'categories', categoryId, 'posts', postId, 'replies', docSnapshot.id);
              await updateDoc(replyRef, { authorId: userDoc.id });
            } else {
              // If user not found, store as anonymous
              authorData = { 
                id: null,
                displayName: 'Anonymous User'
              };
            }
          } else {
            // Try to fetch by UID
            const authorRef = doc(firestore, 'users', replyData.authorId);
            const authorDoc = await getDoc(authorRef);
            if (authorDoc.exists()) {
              authorData = { id: authorDoc.id, ...authorDoc.data() };
            } else {
              authorData = { 
                id: null,
                displayName: 'Anonymous User'
              };
            }
          }
        }

        return {
          id: docSnapshot.id,
          ...replyData,
          author: authorData,
          authorId: authorData?.id || null
        };
      }));
      setReplies(fetchedReplies);
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
      logCustomEvent('view_post', { category_id: categoryId, post_id: postId });
    };
  }, [categoryId, postId, logCustomEvent]);

  // New useEffect for handling hash in URL
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      // Wait for the content to load before scrolling
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          element.classList.add('highlighted');
          setTimeout(() => element.classList.remove('highlighted'), 3000);
        }
      }, 1000); // Adjust this delay if needed
    }
  }, [replies]); // Depend on replies so it runs after they're loaded

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      alert("You must be logged in to reply.");
      return;
    }
    if (!newReply.trim()) return;

    try {
      const replyRef = collection(firestore, 'categories', categoryId, 'posts', postId, 'replies');
      await addDoc(replyRef, {
        content: newReply,
        authorId: auth.currentUser.uid,
        createdAt: new Date(),
        parentReplyId: replyingTo
      });
      setNewReply('');
      setReplyingTo(null);
    } catch (err) {
      console.error("Error adding reply:", err);
      alert("Failed to add reply. Please try again.");
    }
  };

  const handleDeletePost = async () => {
    if (!auth.currentUser) {
      alert("You must be logged in to delete this post.");
      return;
    }

    if (auth.currentUser.uid !== post.authorId && !auth.currentUser.isAdmin) {
      alert("You don't have permission to delete this post.");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    if (!confirmDelete) return;

    try {
      const postRef = doc(firestore, 'categories', categoryId, 'posts', postId);
      await deleteDoc(postRef);
      alert("Post deleted successfully.");
      navigate(`/category/${categoryId}`);
    } catch (err) {
      console.error("Error deleting post:", err);
      alert("Failed to delete post. Please try again.");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const getPostLink = () => {
    return `${window.location.origin}/category/${categoryId}/post/${postId}`;
  };

  const getReplyLink = (replyId) => {
    return `${window.location.origin}/category/${categoryId}/post/${postId}#reply-${replyId}`;
  };

  if (isLoading) return <LoadingSpinner message="Loading post..." />;
  if (error) return <div className="error">{error}</div>;
  if (!post || !category) return <div className="not-found">Post or category not found</div>;

  const canDeletePost = auth.currentUser && (auth.currentUser.uid === post.authorId || auth.currentUser.isAdmin);

  return (
    <div className="post-container">
      <div className="post-header">
        <div className="post-category">
          <Link to={`/category/${categoryId}`}>{category.name}</Link>
        </div>
        <h1 className="post-title">
          {post.title}
          <button className="link-button" onClick={() => copyToClipboard(getPostLink())}>
            <i className="fas fa-link"></i>
          </button>
        </h1>
        <div className="post-meta">
          <span className="post-author">
            <i className="fas fa-user"></i> author:{' '}
            <Link to={`/user/${post.authorId}`}>
              {author ? (author.displayName || author.email) : 'Unknown'}
            </Link>
          </span>
          <span className="post-date">
            <i className="far fa-clock"></i> {post.createdAt?.toDate().toLocaleString()}
          </span>
        </div>
        {post.hashtags && post.hashtags.length > 0 && (
          <div className="post-hashtags">
            {post.hashtags.map((tag, index) => (
              <span key={index} className="hashtag">#{tag}</span>
            ))}
          </div>
        )}
      </div>
      
      <div className="post-content">{post.content}</div>
      
      {canDeletePost && (
        <button onClick={handleDeletePost} className="delete-post-button">
          <i className="fas fa-trash"></i> Delete Post
        </button>
      )}
      
      <div className="replies-section">
        <h2 className="replies-title"><i className="fas fa-comments"></i> Replies</h2>
        {replies.length > 0 ? (
          <ul className="replies-list">
            {replies.map(reply => (
              <li key={reply.id} className="reply-item" id={`reply-${reply.id}`}>
                <div className="reply-content">
                  {reply.content}
                  <button className="link-button" onClick={() => copyToClipboard(getReplyLink(reply.id))}>
                    <i className="fas fa-link"></i>
                  </button>
                </div>
                <div className="reply-meta">
                  <span className="reply-author">
                    <i className="fas fa-user"></i> by:{' '}
                    {reply.author?.id ? (
                      <Link to={`/user/${reply.author.id}`}>
                        {reply.author.displayName || 'Anonymous User'}
                      </Link>
                    ) : (
                      <span>Anonymous User</span>
                    )}
                  </span>
                  <span className="reply-date">
                    <i className="far fa-clock"></i> {reply.createdAt.toDate().toLocaleString()}
                  </span>
                </div>
                <button onClick={() => setReplyingTo(reply.id)} className="reply-to-reply-button">
                  Reply to this comment
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-replies">No replies yet. Be the first to reply!</p>
        )}
      </div>

      <form onSubmit={handleReplySubmit} className="reply-form">
        {replyingTo && (
          <div className="replying-to">
            Replying to comment. <button onClick={() => setReplyingTo(null)}>Cancel</button>
          </div>
        )}
        <textarea
          value={newReply}
          onChange={(e) => setNewReply(e.target.value)}
          placeholder={replyingTo ? "Write your reply to this comment..." : "Write your reply..."}
          className="reply-input"
        />
        <button type="submit" className="reply-button">
          <i className="fas fa-paper-plane"></i> Submit Reply
        </button>
      </form>
    </div>
  );
}

export default Post;

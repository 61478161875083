import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { 
  firestore, 
  doc, 
  getDoc,
  collectionGroup,
  query,
  where,
  orderBy,
  getDocs
} from '../../services/firebase';
import LoadingSpinner from '../common/LoadingSpinner'; // Add this import
import './PublicUserProfile.css';

function PublicUserProfile() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setError("User ID is missing");
        setLoading(false);
        return;
      }

      try {
        console.log("Fetching data for userId:", userId);

        // Fetch user data from Firestore
        const userRef = doc(firestore, 'users', userId);
        const userSnap = await getDoc(userRef);
        
        
        if (userSnap.exists()) {
          setUser({ 
            id: userSnap.id, 
            ...userSnap.data(),
            // If createdAt is not in Firestore, we'll use 'Unknown' as fallback
            createdAt: userSnap.data().createdAt ? new Date(userSnap.data().createdAt) : 'Unknown'
          });
        } else {
          setError("User not found");
          setLoading(false);
          return;
        }

        // Fetch user's posts
        const postsQuery = query(
          collectionGroup(firestore, 'posts'),
          where("authorId", "==", userId),
          orderBy('createdAt', 'desc')
        );
        const postsSnap = await getDocs(postsQuery);
        const postsData = postsSnap.docs.map(doc => ({
          id: doc.id,
          categoryId: doc.ref.parent.parent.id,
          ...doc.data()
        }));
        setPosts(postsData);

        // Fetch user's replies
        const repliesQuery = query(
          collectionGroup(firestore, 'replies'),
          where("authorId", "==", userId),
          orderBy('createdAt', 'desc')
        );
        const repliesSnap = await getDocs(repliesQuery);
        const repliesData = await Promise.all(repliesSnap.docs.map(async doc => {
          const replyData = doc.data();
          const postRef = doc.ref.parent.parent;
          const postSnap = await getDoc(postRef);
          const postExists = postSnap.exists();
          const postData = postExists ? postSnap.data() : null;
          return {
            id: doc.id,
            ...replyData,
            postTitle: postExists ? postData.title : 'Deleted Post',
            categoryId: postExists ? postData.categoryId : null,
            postId: postSnap.id,
            postExists
          };
        }));
        setReplies(repliesData);

        setLoading(false);
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to load user profile. Please try again later.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (loading) return <LoadingSpinner message="Loading user profile..." />;
  if (error) return <div className="error">{error}</div>;
  if (!user) return <div className="not-found">User not found</div>;

  // Updated formatCreatedAt function
  const formatCreatedAt = (createdAt) => {
    if (createdAt && typeof createdAt.toDate === 'function') {
      return createdAt.toDate().toLocaleDateString();
    } else if (createdAt instanceof Date && !isNaN(createdAt)) {
      return createdAt.toLocaleDateString();
    } else if (typeof createdAt === 'string') {
      const date = new Date(createdAt);
      return isNaN(date) ? 'Unknown' : date.toLocaleDateString();
    }
    return 'Unknown';
  };

  return (
    <div className="user-profile public-user-profile">
      <h1>{user.displayName || user.email}</h1>
      <p>Member since: {formatCreatedAt(user.createdAt)}</p>
      
      {user.status && <p>Current Status: {user.status}</p>}

      <div className="profile-content">
        <div className="profile-section">
          <h2>Posts</h2>
          {posts.length > 0 ? (
            <ul className="user-posts">
              {posts.map(post => (
                <li key={post.id} className="user-post-item">
                  <Link to={`/category/${post.categoryId}/post/${post.id}`} className="post-link">
                    <h3>{post.title}</h3>
                    <p>{post.content.substring(0, 100)}...</p>
                    <span className="post-date">
                      {formatCreatedAt(post.createdAt)}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-content">This user hasn't created any posts yet.</p>
          )}
        </div>

        <div className="profile-section">
          <h2>Replies</h2>
          {replies.length > 0 ? (
            <ul className="user-replies">
              {replies.map(reply => (
                <li key={reply.id} className="user-reply-item">
                  {reply.postExists ? (
                    <Link to={`/category/${reply.categoryId}/post/${reply.postId}`} className="reply-link">
                      <h4>Reply to: {reply.postTitle}</h4>
                      <p>{reply.content.substring(0, 100)}...</p>
                      <span className="reply-date">
                        {formatCreatedAt(reply.createdAt)}
                      </span>
                    </Link>
                  ) : (
                    <div className="deleted-post-reply">
                      <h4>Reply to: {reply.postTitle}</h4>
                      <p>{reply.content.substring(0, 100)}...</p>
                      <span className="reply-date">
                        {formatCreatedAt(reply.createdAt)}
                      </span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-content">This user hasn't replied to any posts yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PublicUserProfile;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore, doc, getDoc, updateDoc, arrayRemove, arrayUnion } from '../../services/firebase';
import { auth } from '../../services/firebase';
import { useAnalytics } from '../../hooks/useAnalytics';
import './EventDetails.css';

function EventDetails() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(firestore, 'events', eventId));
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() });
        } else {
          setError("Event not found");
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching event:", err);
        setError("Failed to load event details");
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const isUserInWaitlist = () => {
    return auth.currentUser && event?.waitlist?.includes(auth.currentUser.uid);
  };

  const handleWaitlist = async () => {
    if (!auth.currentUser) {
      navigate('/login', { state: { redirect: `/events/${eventId}` } });
      return;
    }

    try {
      const eventRef = doc(firestore, 'events', eventId);
      const userId = auth.currentUser.uid;
      const isInWaitlist = isUserInWaitlist();

      if (isInWaitlist) {
        // Remove from waitlist
        await updateDoc(eventRef, {
          waitlist: arrayRemove(userId)
        });
        setEvent(prev => ({
          ...prev,
          waitlist: prev.waitlist.filter(id => id !== userId)
        }));
      } else {
        // Add to waitlist
        await updateDoc(eventRef, {
          waitlist: arrayUnion(userId)
        });
        setEvent(prev => ({
          ...prev,
          waitlist: [...(prev.waitlist || []), userId]
        }));
      }

      logUserAction('waitlist_toggle', {
        event_id: eventId,
        event_name: event.name,
        action: isInWaitlist ? 'remove' : 'join'
      });
    } catch (err) {
      console.error("Error updating waitlist:", err);
      setError("Failed to update waitlist");
    }
  };

  if (loading) return <div className="loading">Loading event details...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!event) return <div className="error">Event not found</div>;

  return (
    <div className="event-details-container">
      <div className="event-banner">
        <span className="section-tag">Event Details</span>
        <h1>{event.name}</h1>
        <div className="event-metadata">
          <div className="metadata-item">
            <i className="fas fa-calendar"></i>
            {event.date?.toDate().toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              timeZoneName: 'short'
            })}
          </div>
          <div className="metadata-item">
            <i className="fas fa-clock"></i>
            {event.duration}
          </div>
          <div className="metadata-item">
            <i className="fas fa-map-marker-alt"></i>
            {event.location}
          </div>
          <div className="metadata-item">
            <i className="fas fa-users"></i>
            Capacity: {event.capacity} participants
          </div>
          <div className="metadata-item">
            <i className="fas fa-ticket-alt"></i>
            {event.price}
          </div>
        </div>
      </div>

      <div className="event-info">
        {event.imageUrl && (
          <div className="event-image-container">
            <img src={event.imageUrl} alt={event.name} className="event-detail-image" />
          </div>
        )}
        
        <div className="event-description">
          <h2>About this Event</h2>
          <p>{event.description}</p>
        </div>

        <button 
          className={`waitlist-button ${isUserInWaitlist() ? 'joined' : ''}`}
          onClick={handleWaitlist}
        >
          {isUserInWaitlist() ? 'Leave Waitlist' : 'Join Waitlist'}
        </button>
      </div>
    </div>
  );
}

export default EventDetails;

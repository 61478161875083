import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <div className="privacy-hero">
        <h1>Privacy Policy</h1>
        <p className="last-updated">Last updated: March 18, 2024</p>
      </div>

      <div className="privacy-content">
        <div className="privacy-nav">
          <div className="nav-header">Contents</div>
          <ul>
            <li><a href="#introduction">1. Introduction</a></li>
            <li><a href="#information">2. Information We Collect</a></li>
            <li><a href="#usage">3. How We Use Your Information</a></li>
            <li><a href="#sharing">4. Information Sharing</a></li>
            <li><a href="#rights">5. Your Rights</a></li>
            <li><a href="#contact">6. Contact Us</a></li>
          </ul>
        </div>

        <div className="privacy-sections">
          <section id="introduction">
            <h2>1. Introduction</h2>
            <p>Welcome to MasterMelon. We are committed to protecting your personal information and your right to privacy. This Privacy Policy describes how we collect, use, and handle your information when you use our services.</p>
            <p>By using MasterMelon, you agree to the collection and use of information in accordance with this policy.</p>
          </section>

          <section id="information">
            <h2>2. Information We Collect</h2>
            <div className="info-grid">
              <div className="info-card">
                <i className="fas fa-user"></i>
                <h3>Personal Information</h3>
                <p>Name, email address, and profile information you provide</p>
              </div>
              <div className="info-card">
                <i className="fas fa-globe"></i>
                <h3>Usage Data</h3>
                <p>Browser type, IP address, and how you interact with our services</p>
              </div>
              <div className="info-card">
                <i className="fas fa-shopping-cart"></i>
                <h3>Transaction Data</h3>
                <p>Purchase history and payment information</p>
              </div>
            </div>
          </section>

          <section id="usage">
            <h2>3. How We Use Your Information</h2>
            <ul className="usage-list">
              <li>
                <i className="fas fa-check"></i>
                <span>To provide and maintain our services</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To notify you about changes to our services</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To provide customer support</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To gather analysis or valuable information to improve our services</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To detect, prevent and address technical issues</span>
              </li>
            </ul>
          </section>

          <section id="sharing">
            <h2>4. Information Sharing</h2>
            <p>We do not share your personal information with third parties except as described in this privacy policy. We may share your information in the following situations:</p>
            <div className="sharing-info">
              <div className="sharing-item">
                <h3>Business Partners</h3>
                <p>We may share your information with our business partners to offer you certain products, services or promotions.</p>
              </div>
              <div className="sharing-item">
                <h3>Legal Requirements</h3>
                <p>We may disclose your information where required by law or to protect our rights.</p>
              </div>
            </div>
          </section>

          <section id="rights">
            <h2>5. Your Rights</h2>
            <div className="rights-container">
              <div className="right-item">
                <i className="fas fa-eye"></i>
                <h3>Access</h3>
                <p>You can request access to your personal data</p>
              </div>
              <div className="right-item">
                <i className="fas fa-edit"></i>
                <h3>Update</h3>
                <p>You can update your information at any time</p>
              </div>
              <div className="right-item">
                <i className="fas fa-trash"></i>
                <h3>Delete</h3>
                <p>You can request deletion of your data</p>
              </div>
            </div>
          </section>

          <section id="contact">
            <h2>6. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <div className="contact-info">
              <div className="contact-item">
                <i className="fas fa-envelope"></i>
                <p>Email: info@mastermelon.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.css';
import { 
  firestore, 
  auth, 
  collection, 
  getDocs, 
  query, 
  orderBy, 
  limit,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove 
} from '../services/firebase';
import { useAnalytics } from '../hooks/useAnalytics';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faThumbsUp, 
  faRocket, 
  faBrain,
  faArrowRight,
  faCalendar,
  faClock,
  faMapMarkerAlt,
  faUsers,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

// Add all icons to the library
library.add(
  faThumbsUp, 
  faRocket, 
  faBrain,
  faArrowRight,
  faCalendar,
  faClock,
  faMapMarkerAlt,
  faUsers,
  faPlus
);

function Home() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    logUserAction('view_home_page');
  }, [logUserAction]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(firestore, 'events');
        const q = query(eventsRef, orderBy('date', 'asc'), limit(3));
        const snapshot = await getDocs(q);
        const fetchedEvents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          waitlist: doc.data().waitlist || []
        }));
        setEvents(fetchedEvents);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchEvents();
  }, []);

  const handleFeatureClick = (featureName) => {
    logUserAction('feature_click', { feature_name: featureName });
  };

  const handleCtaClick = (ctaName) => {
    logUserAction('cta_click', { cta_name: ctaName });
  };

  const isUserInWaitlist = (event) => {
    return auth.currentUser && event?.waitlist?.includes(auth.currentUser.uid);
  };

  const handleWaitlist = async (event, e) => {
    e.preventDefault(); // Prevent navigation when clicking the button
    
    if (!auth.currentUser) {
      navigate('/login', { state: { redirect: '/' } });
      return;
    }

    try {
      const eventRef = doc(firestore, 'events', event.id);
      const userId = auth.currentUser.uid;
      const isInWaitlist = isUserInWaitlist(event);

      if (isInWaitlist) {
        // Remove from waitlist
        await updateDoc(eventRef, {
          waitlist: arrayRemove(userId)
        });
        setEvents(prevEvents => 
          prevEvents.map(e => 
            e.id === event.id 
              ? { ...e, waitlist: e.waitlist.filter(id => id !== userId) }
              : e
          )
        );
      } else {
        // Add to waitlist
        await updateDoc(eventRef, {
          waitlist: arrayUnion(userId)
        });
        setEvents(prevEvents => 
          prevEvents.map(e => 
            e.id === event.id 
              ? { ...e, waitlist: [...(e.waitlist || []), userId] }
              : e
          )
        );
      }

      logUserAction('waitlist_toggle', {
        event_id: event.id,
        event_name: event.name,
        action: isInWaitlist ? 'remove' : 'join'
      });
    } catch (err) {
      console.error("Error updating waitlist:", err);
    }
  };

  return (
    <div className="home-container">
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            <span className="highlight">Elevate</span> Your Professional Journey
          </h1>
          <p className="hero-subtitle">Join a community of ambitious professionals sharing insights, experiences, and opportunities</p>
          <div className="cta-buttons">
            <Link to="/community" className="cta-button primary" onClick={() => handleCtaClick('join_community')}>
              Join Community
            </Link>
            <Link to="/events" className="cta-button secondary" onClick={() => handleCtaClick('explore_events')}>
              Explore Events
            </Link>
            {/* <Link to="/marketplace" className="cta-button outline" onClick={() => handleCtaClick('browse_resources')}>
              Browse Resources
            </Link> */}
          </div>
        </div>
      </section>

      <section className="features-section">
        <div className="section-header">
          <span className="section-tag">Why Choose Us</span>
          <h2>Unlock Your Full Potential</h2>
          <p className="section-subtitle">Everything you need to accelerate your career growth</p>
        </div>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon="thumbs-up" />
            </div>
            <div className="feature-content">
              <h3>Peer Learning Network</h3>
              <p>Connect with professionals who share your ambitions</p>
            </div>
          </div>
          <div className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon="rocket" />
            </div>
            <div className="feature-content">
              <h3>Career Acceleration</h3>
              <p>Access proven frameworks and strategies</p>
            </div>
          </div>
          <div className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon="brain" />
            </div>
            <div className="feature-content">
              <h3>Skill Mastery</h3>
              <p>Master in-demand skills through expert-led discussions</p>
            </div>
          </div>
        </div>
      </section>

      <section className="events-section">
        <div className="section-header">
          <span className="section-tag">Upcoming Events</span>
          <h2>Join Our Events</h2>
          <p className="section-subtitle">Learn from industry experts and connect with peers</p>
        </div>
        <div className="events-grid">
          {events.slice(0, 3).map(event => (
            <div key={event.id} className="event-card">
              {event.imageUrl && <img src={event.imageUrl} alt={event.name} className="event-image" />}
              <div className="event-content">
                <div className="event-header">
                  <h3>{event.name}</h3>
                  <div className="event-price">{event.price}</div>
                </div>
                <p className="event-description">{event.description}</p>
                <div className="event-info">
                  <div className="event-details">
                    <span className="event-date">
                      <FontAwesomeIcon icon="calendar" />
                      {event.date?.toDate().toLocaleString()}
                    </span>
                    <span className="event-duration">
                      <FontAwesomeIcon icon="clock" />
                      {event.duration}
                    </span>
                    <span className="event-location">
                      <FontAwesomeIcon icon="map-marker-alt" />
                      {event.location}
                    </span>
                    <span className="event-capacity">
                      <FontAwesomeIcon icon="users" />
                      Capacity: {event.capacity}
                    </span>
                  </div>
                </div>
                <div className="event-actions">
                  <Link to={`/events/${event.id}`} className="view-details-button">
                    View Details <FontAwesomeIcon icon="arrow-right" />
                  </Link>
                  <button 
                    className={`waitlist-button ${isUserInWaitlist(event) ? 'joined' : ''}`}
                    onClick={(e) => handleWaitlist(event, e)}
                  >
                    {isUserInWaitlist(event) ? 'Leave Waitlist' : 'Join Waitlist'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="view-all-container">
          <Link to="/events" className="view-all-link" onClick={() => handleFeatureClick('view_all_events')}>
            View All Events <FontAwesomeIcon icon="arrow-right" />
          </Link>
        </div>
      </section>

      <section className="join-section">
        <div className="join-content">
          <span className="section-tag">Get Started</span>
          <h2>Ready to Transform Your Career?</h2>
          <p>Join thousands of professionals already growing with MasterMelon</p>
          <Link to="/signup" className="cta-button primary" onClick={() => handleCtaClick('start_journey')}>
            Start Your Journey
            <FontAwesomeIcon icon="arrow-right" />
          </Link>
        </div>
        <div className="background-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
      </section>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getStreams } from '../../services/api';
import './StreamList.css';

function StreamList() {
  const [streams, setStreams] = useState([]);

  useEffect(() => {
    const fetchStreams = async () => {
      const fetchedStreams = await getStreams();
      setStreams(fetchedStreams);
    };
    fetchStreams();
  }, []);

  return (
    <div className="stream-list">
      <h2>Live Streams</h2>
      <Link to="/create-stream" className="create-stream-link">Start a Stream</Link>
      {streams.length > 0 ? (
        <div className="streams">
          {streams.map((stream) => (
            <div key={stream.id} className="stream-item">
              <Link to={`/stream/${stream.id}`} className="stream-link">
                <div 
                  className="stream-thumbnail" 
                  style={{backgroundImage: `url(${stream.thumbnailUrl || '/default-thumbnail.jpg'})`}}
                ></div>
                <div className="stream-info">
                  <div className="stream-title">{stream.title}</div>
                  <div className="stream-meta">
                    <div className="stream-streamer">By {stream.streamerName}</div>
                    <div className="stream-category">{stream.category}</div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-streams">No live streams at the moment.</p>
      )}
    </div>
  );
}

export default StreamList;

import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <section className="about-hero">
        <h1>About MasterMelon</h1>
        <p className="subtitle">Empowering the next generation of professionals</p>
      </section>

      <section className="about-mission">
        <div className="mission-content">
          <h2>Our Mission</h2>
          <p>
            At MasterMelon, we believe in the power of community-driven professional growth. 
            Our platform connects ambitious young professionals with the resources, mentorship, 
            and peer support they need to thrive in today's dynamic workplace.
          </p>
        </div>
      </section>

      <section className="about-values">
        <h2>Our Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <i className="fas fa-handshake"></i>
            <h3>Community First</h3>
            <p>We believe in the power of collective growth and shared experiences.</p>
          </div>
          <div className="value-card">
            <i className="fas fa-lightbulb"></i>
            <h3>Continuous Learning</h3>
            <p>Knowledge sharing and skill development are at our core.</p>
          </div>
          <div className="value-card">
            <i className="fas fa-users"></i>
            <h3>Inclusive Growth</h3>
            <p>Everyone deserves access to professional development opportunities.</p>
          </div>
          <div className="value-card">
            <i className="fas fa-shield-alt"></i>
            <h3>Trust & Support</h3>
            <p>Building a safe and supportive environment for all members.</p>
          </div>
        </div>
      </section>

      <section className="about-story">
        <div className="story-content">
          <h2>Our Story</h2>
          <p>
            Founded in 2024, MasterMelon emerged from a simple observation: young professionals 
            needed a better way to connect, learn, and grow together. What started as a small 
            community has grown into a vibrant platform where thousands of professionals support 
            each other's growth journey.
          </p>
        </div>
      </section>

      <section className="about-join">
        <h2>Join Our Community</h2>
        <p>Be part of a growing network of ambitious professionals</p>
        <button className="join-button" onClick={() => window.location.href='/signup'}>
          Get Started
        </button>
      </section>
    </div>
  );
}

export default About;

import React, { useEffect } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import CategoryList from '../Forum/CategoryList';
import RecentActivity from '../Community/RecentActivity'; // We'll create this from Home.js content
import './Community.css';

function Community() {
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    logUserAction('view_community_page');
  }, [logUserAction]); // Add logUserAction to dependencies

  return (
    <div className="community-container">
      <section className="categories-section">
        <CategoryList />
      </section>
      
      <section className="recent-activity-section">
        <RecentActivity />
      </section>
    </div>
  );
}

export default Community;

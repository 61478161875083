import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ScrollToTop from './components/common/ScrollToTop';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import { useAnalytics } from './hooks/useAnalytics';

// Public Components
import Home from './components/Home';
import Community from './components/Community/Community';
import CategoryList from './components/Forum/CategoryList';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import ForgotPassword from './components/Auth/ForgotPassword';
import About from './components/About/About';
import Contact from './components/Footer/Contact';
import Events from './components/Event/Events';
import EventDetails from './components/Event/EventDetails';

// Protected Components
// Protected Components
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './components/User/UserProfile';
import CreatePost from './components/Forum/CreatePost';
import CreateStream from './components/LiveStream/CreateStream';
import Marketplace from './components/Marketplace/Marketplace';

// Semi-Protected Components
import PostList from './components/Forum/PostList';
import Post from './components/Forum/Post';
import StreamList from './components/LiveStream/StreamList';
import Stream from './components/LiveStream/Stream';
import PublicUserProfile from './components/User/PublicUserProfile';

// Legal Pages
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import TermsOfService from './components/Footer/TermsOfService';
import CookiePolicy from './components/Footer/CookiePolicy';

// Route configurations
const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/community", element: <Community /> },
  { path: "/categories", element: <CategoryList /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms", element: <TermsOfService /> },
  { path: "/cookies", element: <CookiePolicy /> },
  { path: "/marketplace", element: <Marketplace /> },
  { path: "/category/:categoryId/post/:postId", element: <Post /> },
  { path: "/post/:postId", element: <Post /> },
  { path: "/events", element: <Events /> },
  { path: "/events/:eventId", element: <EventDetails /> },
];

const protectedRoutes = [
  { path: "/profile", element: <UserProfile /> },
  { path: "/create-post", element: <CreatePost /> },
  { path: "/category/:categoryId/create-post", element: <CreatePost /> },
  { path: "/create-stream", element: <CreateStream /> }
];

const semiProtectedRoutes = [
  { path: "/category/:categoryId", element: <PostList /> },
  { path: "/streams", element: <StreamList /> },
  { path: "/stream/:streamId", element: <Stream /> },
  { path: "/user/:userId", element: <PublicUserProfile /> }
];

function AppContent() {
  useAnalytics(); // Use analytics here, inside the Router context

  return (
    <>
      <Navigation />
      <Routes>
        {/* Public Routes */}
        {publicRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}

        {/* Protected Routes */}
        {protectedRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}

        {/* Semi-Protected Routes */}
        {semiProtectedRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <div className="app">
        <Router>
          <ScrollToTop />
          <AppContent />
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;

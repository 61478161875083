import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore, createUserWithEmailAndPassword } from '../../services/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useAnalytics } from '../../hooks/useAnalytics';
import './Signup.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [foundUs, setFoundUs] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { logCustomEvent } = useAnalytics();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    if (!foundUs) {
      setError('Please select where you found us');
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document in Firestore
      await setDoc(doc(firestore, 'users', userCredential.user.uid), {
        displayName: displayName,
        email: email,
        foundUs: foundUs,
        createdAt: new Date().toISOString(),
      });

      logCustomEvent('sign_up', { method: 'email', found_us: foundUs });

      // Redirect to home page after successful signup
      navigate('/');
    } catch (error) {
      console.error('Error during sign up:', error);
      setError('Failed to create an account: ' + error.message);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSignup} className="signup-form">
        <div className="form-group">
          <label htmlFor="displayName">Display Name</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="foundUs">Where did you find us?</label>
          <select
            id="foundUs"
            value={foundUs}
            onChange={(e) => setFoundUs(e.target.value)}
            required
          >
            <option value="">Select an option</option>
            <option value="search">Search Engine</option>
            <option value="social">Social Media</option>
            <option value="friend">Friend Recommendation</option>
            <option value="ad">Advertisement</option>
            <option value="other">Other</option>
          </select>
        </div>
        <button type="submit" className="signup-button">Sign Up</button>
      </form>
      {/* New line for login link */}
      <p className="login-link">
        Already have an account? <Link to="/login">Log in</Link>
      </p>
    </div>
  );
}

export default Signup;

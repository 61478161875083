import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  firestore, 
  auth, 
  collectionGroup, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  getDoc,
  setDoc
} from '../../services/firebase';
import { updatePassword } from 'firebase/auth';
import { FaPencilAlt } from 'react-icons/fa';
import './UserProfile.css';
import LoadingSpinner from '../common/LoadingSpinner';

function UserProfile() {
  const [userPosts, setUserPosts] = useState([]);
  const [userReplies, setUserReplies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [status, setStatus] = useState('This person is lazy...');
  const [isEditingStatus, setIsEditingStatus] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) {
        setError("You must be logged in to view your profile");
        setIsLoading(false);
        return;
      }

      try {
        // Fetch user data
        const userRef = doc(firestore, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUsername(userDoc.data().displayName || '');
          setStatus(userDoc.data().status || 'The person is lazy...');
        }

        // Fetch user posts
        const postsQuery = query(
          collectionGroup(firestore, 'posts'),
          where('authorId', '==', auth.currentUser.uid),
          orderBy('createdAt', 'desc')
        );
        const snapshot = await getDocs(postsQuery);
        const fetchedPosts = snapshot.docs.map(doc => ({
          id: doc.id,
          categoryId: doc.ref.parent.parent.id,
          ...doc.data()
        }));
        setUserPosts(fetchedPosts);

        // Fetch user's replies using collectionGroup
        const repliesQuery = query(
          collectionGroup(firestore, 'replies'),
          where('authorId', '==', auth.currentUser.uid),
          orderBy('createdAt', 'desc')
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        const fetchedReplies = await Promise.all(repliesSnapshot.docs.map(async doc => {
          const replyData = doc.data();
          const postRef = doc.ref.parent.parent;
          
          try {
            const postSnap = await getDoc(postRef);
            const postData = postSnap.exists() ? postSnap.data() : null;
            
            // Only return reply data if the parent post exists and has required data
            if (postData && postData.title) {
              return {
                id: doc.id,
                ...replyData,
                postTitle: postData.title,
                categoryId: postData.categoryId,
                postId: postSnap.id
              };
            } else {
              // Handle case where parent post is missing or incomplete
              return {
                id: doc.id,
                ...replyData,
                postTitle: 'Deleted Post',
                categoryId: 'unknown',
                postId: postSnap.id
              };
            }
          } catch (err) {
            console.error("Error fetching parent post:", err);
            // Return reply with placeholder data for deleted post
            return {
              id: doc.id,
              ...replyData,
              postTitle: 'Deleted Post',
              categoryId: 'unknown',
              postId: 'unknown'
            };
          }
        }));

        // Filter out any null values that might have occurred during the fetch
        const validReplies = fetchedReplies.filter(reply => reply !== null);
        setUserReplies(validReplies);

      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to load your profile data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleUsernameUpdate = async () => {
    if (!newUsername.trim()) {
      setError("Username cannot be empty");
      return;
    }

    try {
      const userRef = doc(firestore, 'users', auth.currentUser.uid);
      await setDoc(userRef, { displayName: newUsername.trim() }, { merge: true });
      setUsername(newUsername);
      setNewUsername('');
      alert("Username updated successfully!");
    } catch (err) {
      console.error("Error updating username:", err);
      setError("Failed to update username. Please try again.");
    }
  };

  const handlePasswordUpdate = async () => {
    if (!newPassword.trim()) {
      setError("Password cannot be empty");
      return;
    }

    try {
      await updatePassword(auth.currentUser, newPassword.trim());
      setNewPassword('');
      alert("Password updated successfully!");
    } catch (err) {
      console.error("Error updating password:", err);
      setError("Failed to update password. Please try again.");
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      const userRef = doc(firestore, 'users', auth.currentUser.uid);
      const updatedStatus = newStatus.trim() || 'The person is lazy...';
      await setDoc(userRef, { status: updatedStatus }, { merge: true });
      setStatus(updatedStatus);
      setIsEditingStatus(false);
    } catch (err) {
      console.error("Error updating status:", err);
      setError("Failed to update status. Please try again.");
    }
  };

  if (isLoading) return <LoadingSpinner message="Loading your profile..." />;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="user-profile">
      <div className="profile-header">
        <div className="profile-avatar">
          {username ? username[0].toUpperCase() : 'U'}
        </div>
        <h1>{username || 'Anonymous User'}</h1>
        <p>{auth.currentUser?.email}</p>
        <div className="status-container">
          <span className="status-label">Status:</span>
          {isEditingStatus ? (
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              onBlur={() => handleStatusUpdate(status)}
              onKeyDown={(e) => e.key === 'Enter' && handleStatusUpdate(status)}
              autoFocus
            />
          ) : (
            <>
              <span className="status-text">{status}</span>
              <FaPencilAlt className="edit-icon" onClick={() => setIsEditingStatus(true)} />
            </>
          )}
        </div>
      </div>
      
      <div className="profile-content">
        <div className="profile-section">
          <h2>Update Profile</h2>
          <div className="update-card">
            <h3>Change Username</h3>
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder="Enter new username"
            />
            <button className="update-button" onClick={handleUsernameUpdate}>Update Username</button>
          </div>
          
          <div className="update-card">
            <h3>Change Password</h3>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <button className="update-button" onClick={handlePasswordUpdate}>Update Password</button>
          </div>
        </div>

        <div className="profile-section">
          <h2>Your Posts</h2>
          {userPosts.length > 0 ? (
            <ul className="user-posts">
              {userPosts.map(post => (
                <li key={post.id} className="user-post-item">
                  <Link to={`/category/${post.categoryId}/post/${post.id}`} className="post-link">
                    <h3>{post.title}</h3>
                    <p>{post.content.substring(0, 100)}...</p>
                    <span className="post-date">
                      {post.createdAt?.toDate().toLocaleDateString()}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-content">You haven't created any posts yet.</p>
          )}
        </div>

        <div className="profile-section">
          <h2>Your Replies</h2>
          {userReplies.length > 0 ? (
            <ul className="user-replies">
              {userReplies.map(reply => (
                <li key={reply.id} className="user-reply-item">
                  <Link to={`/category/${reply.categoryId}/post/${reply.postId}`} className="reply-link">
                    <h4>Reply to: {reply.postTitle}</h4>
                    <p>{reply.content.substring(0, 100)}...</p>
                    <span className="reply-date">
                      {reply.createdAt?.toDate().toLocaleDateString()}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-content">You haven't replied to any posts yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfile;

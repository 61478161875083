import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  firestore, 
  collection, 
  getDocs, 
  doc, 
  updateDoc, 
  arrayUnion, 
  arrayRemove
} from '../../services/firebase';
import { auth } from '../../services/firebase';
import { useAnalytics } from '../../hooks/useAnalytics';
import './Events.css';

function Events() {
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
        const categoriesData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesData);

        // Fetch all events and include waitlist information
        const eventsSnapshot = await getDocs(collection(firestore, 'events'));
        const eventsData = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          waitlist: doc.data().waitlist || []
        }));
        
        // Separate featured events
        setFeaturedEvents(eventsData.filter(event => event.featured));
        setEvents(eventsData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load events");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleWaitlist = async (event) => {
    if (!auth.currentUser) {
      navigate('/login', { state: { redirect: '/events' } });
      return;
    }

    try {
      const eventRef = doc(firestore, 'events', event.id);
      const userId = auth.currentUser.uid;
      const isInWaitlist = event.waitlist?.includes(userId);

      if (isInWaitlist) {
        // Remove from waitlist
        await updateDoc(eventRef, {
          waitlist: arrayRemove(userId)
        });
        setEvents(events.map(e => 
          e.id === event.id 
            ? { ...e, waitlist: e.waitlist.filter(id => id !== userId) }
            : e
        ));
      } else {
        // Add to waitlist
        await updateDoc(eventRef, {
          waitlist: arrayUnion(userId)
        });
        setEvents(events.map(e => 
          e.id === event.id 
            ? { ...e, waitlist: [...(e.waitlist || []), userId] }
            : e
        ));
      }

      logUserAction('waitlist_toggle', {
        event_id: event.id,
        event_name: event.name,
        action: isInWaitlist ? 'remove' : 'join'
      });
    } catch (err) {
      console.error("Error updating waitlist:", err);
      setError("Failed to update waitlist");
    }
  };

  const isUserInWaitlist = (event) => {
    return auth.currentUser && event.waitlist?.includes(auth.currentUser.uid);
  };

  if (loading) return <div className="loading">Loading events...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="events-container">
      {/* <PageBanner 
        tag="Upcoming Events"
        title="Join Our Events"
        subtitle="Expand your knowledge and network with industry experts"
      /> */}
      {/* Featured Events Section */}
      <section className="featured-events">
        <div className="section-header">
          <span className="section-tag">Featured Events</span>
          <h2>Don't Miss Out</h2>
          <p className="section-subtitle">Join our most popular upcoming events</p>
        </div>
        <div className="events-grid">
          {featuredEvents.map(event => (
            <div key={event.id} className="event-card featured">
              {event.imageUrl && <img src={event.imageUrl} alt={event.name} className="event-image" />}
              <div className="event-content">
                <div className="event-header">
                  <h3>{event.name}</h3>
                  <div className="event-price">{event.price}</div>
                </div>
                <p className="event-description">{event.description}</p>
                <div className="event-info">
                  <div className="event-details">
                    <span className="event-date">
                      <i className="far fa-calendar"></i>
                      {event.date?.toDate().toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZoneName: 'short'
                      })}
                    </span>
                    <span className="event-duration">
                      <i className="far fa-clock"></i>
                      {event.duration}
                    </span>
                    <span className="event-location">
                      <i className="fas fa-map-marker-alt"></i>
                      {event.location}
                    </span>
                    <span className="event-capacity">
                      <i className="fas fa-users"></i>
                      Capacity: {event.capacity} participants
                    </span>
                  </div>
                </div>
                <div className="event-actions">
                  <Link to={`/events/${event.id}`} className="view-details-button">
                    View Details <i className="fas fa-arrow-right"></i>
                  </Link>
                  <button 
                    className={`waitlist-button ${isUserInWaitlist(event) ? 'joined' : ''}`}
                    onClick={() => handleWaitlist(event)}
                  >
                    {isUserInWaitlist(event) ? 'Leave Waitlist' : 'Join Waitlist'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Events by Category Section */}
      {categories.map(category => {
        const categoryEvents = events.filter(event => event.categoryId === category.id);
        if (categoryEvents.length === 0) return null;

        return (
          <section key={category.id} className="category-events">
            <div className="section-header">
              <span className="section-tag">{category.name}</span>
              <h2>Upcoming Events</h2>
              <p className="section-subtitle">Discover events in {category.name}</p>
            </div>
            <div className="events-grid">
              {categoryEvents.map(event => (
                <div key={event.id} className="event-card">
                  {event.imageUrl && <img src={event.imageUrl} alt={event.name} className="event-image" />}
                  <div className="event-content">
                    <div className="event-header">
                      <h3>{event.name}</h3>
                      <div className="event-price">{event.price}</div>
                    </div>
                    <p className="event-description">{event.description}</p>
                    <div className="event-info">
                      <div className="event-details">
                        <span className="event-date">
                          <i className="far fa-calendar"></i>
                          {event.date?.toDate().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZoneName: 'short'
                          })}
                        </span>
                        <span className="event-duration">
                          <i className="far fa-clock"></i>
                          {event.duration}
                        </span>
                        <span className="event-location">
                          <i className="fas fa-map-marker-alt"></i>
                          {event.location}
                        </span>
                        <span className="event-capacity">
                          <i className="fas fa-users"></i>
                          Capacity: {event.capacity}
                        </span>
                      </div>
                    </div>
                    <div className="event-actions">
                      <Link to={`/events/${event.id}`} className="view-details-button">
                        View Details <i className="fas fa-arrow-right"></i>
                      </Link>
                      <button 
                        className={`waitlist-button ${isUserInWaitlist(event) ? 'joined' : ''}`}
                        onClick={() => handleWaitlist(event)}
                      >
                        {isUserInWaitlist(event) ? 'Leave Waitlist' : 'Join Waitlist'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        );
      })}
    </div>
  );
}

export default Events;

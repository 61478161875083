import React, { useState, useEffect } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { addDoc, collection, firestore } from '../../services/firebase';
import './Marketplace.css';
import medicineImage from './image/medicine.jpg';
import bathImage from './image/bath.png';
import footImage from './image/foot.png';

function Marketplace() {
  const { logUserAction, logFormSubmission, logError } = useAnalytics();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    logUserAction('view_marketplace');
  }, [logUserAction]);

  const products = [
    { 
      id: 1, 
      name: 'Herb Hair Shampoo for HAIR LOSS (10 Packs)', 
      originalPrice: 89.99,
      price: 39.99, 
      image: medicineImage,
      description: 'A natural, herbal solution to combat hair loss and promote healthy hair growth.'
    },
    { 
      id: 2, 
      name: 'Herbal Bath Bomb for IMMUNE SYSTEM (5 counts)', 
      originalPrice: 74.99,
      price: 29.99, 
      image: bathImage,
      description: 'Boost your immune system with this soothing herbal bath blend.'
    },
    { 
      id: 3, 
      name: 'Herbal Foot Soak Package for STRESS RELIEF', 
      originalPrice: 49.99,
      price: 24.99, 
      image: footImage,
      description: 'Relax and rejuvenate with our traditional Chinese herbal foot soak blend.'
    },
  ];

  const handlePreorder = async (e) => {
    e.preventDefault();
    if (selectedProduct) {
      setIsSubmitting(true);
      setErrorMessage('');

      try {
        const preorderRef = await addDoc(collection(firestore, 'preorders'), {
          productId: selectedProduct.id,
          productName: selectedProduct.name,
          price: selectedProduct.price,
          quantity: quantity,
          totalPrice: selectedProduct.price * quantity,
          customerName: name,
          customerEmail: email,
          customerZipCode: zipCode,
          createdAt: new Date(),
        });

        logFormSubmission('preorder_form', true);
        logUserAction('complete_preorder', {
          product_id: selectedProduct.id,
          product_name: selectedProduct.name,
          price: selectedProduct.price
        });

        console.log('Preorder submitted:', { product: selectedProduct, name, email, zipCode, quantity, preorderId: preorderRef.id });
        
        setSelectedProduct(null);
        setName('');
        setEmail('');
        setZipCode('');
        setQuantity(1);
        alert('Preorder submitted successfully!');
      } catch (error) {
        console.error('Error submitting preorder:', error);
        setErrorMessage('An error occurred while submitting your preorder. Please try again.');
        logError('preorder_error', error.message, 'Marketplace');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handlePreorderClick = (product) => {
    logUserAction('click_preorder_button', {
      product_id: product.id,
      product_name: product.name,
      product_price: product.price
    });
    setSelectedProduct(product);
  };

  const handleProductView = (product) => {
    logUserAction('view_product_details', {
      product_id: product.id,
      product_name: product.name
    });
  };

  return (
    <div className="marketplace-container">
      <section className="marketplace-hero">
        <h1>Self-Care Essentials</h1>
        <p className="hero-subtitle">
          Discover premium wellness products designed for busy professionals. 
          Pre-order now to lock in special pricing.
        </p>
        <div className="trust-badges">
          <span className="badge"><i className="fas fa-truck"></i> Free Shipping Over $100</span>
          <span className="badge"><i className="fas fa-shield-alt"></i> Pay When Ready to Ship</span>
        </div>
      </section>

      <div className="marketplace-content">
        <div className="product-grid">
          {products.map((product) => (
            <div 
              key={product.id} 
              className="product-card"
              onClick={() => handleProductView(product)}
            >
              <div className="product-image-container">
                <img src={product.image} alt={product.name} className="product-image" />
                <span className="discount-badge">
                  {Math.round((1 - product.price / product.originalPrice) * 100)}% OFF
                </span>
              </div>
              <div className="product-info">
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
                <div className="price-container">
                  <span className="original-price">${product.originalPrice.toFixed(2)}</span>
                  <span className="promotional-price">${product.price.toFixed(2)}</span>
                </div>
                <div className="payment-info">
                  <p className="promo-label">Pre-order Special Price</p>
                  <p className="payment-note">*Reserve now, pay when ready to ship</p>
                </div>
                <button 
                  className="preorder-button" 
                  onClick={() => handlePreorderClick(product)}
                >
                  Reserve Now
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          ))}
        </div>

        {selectedProduct && (
          <div className="preorder-modal">
            <div className="modal-content">
              <h2>Reserve {selectedProduct.name}</h2>
              <p className="modal-subtitle">Lock in special pricing today</p>
              <form onSubmit={handlePreorder} className="preorder-form">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Quantity:</label>
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                    min="1"
                    required
                  />
                </div>
                <div className="total-price">
                  Total: ${(selectedProduct.price * quantity).toFixed(2)}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="form-actions">
                  <button type="button" className="cancel-button" onClick={() => setSelectedProduct(null)}>
                    Cancel
                  </button>
                  <button type="submit" className="submit-button" disabled={isSubmitting}>
                    {isSubmitting ? 'Processing...' : 'Confirm Pre-order'}
                  </button>
                </div>
                <div className="reservation-benefits">
                  <p><i className="fas fa-check"></i> Lock in special pre-order pricing</p>
                  <p><i className="fas fa-check"></i> No upfront payment needed</p>
                  <p><i className="fas fa-check"></i> Pay only when ready to ship</p>
                  <p><i className="fas fa-check"></i> Cancel anytime before payment</p>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Marketplace;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, auth, collection, addDoc, getDocs } from '../../services/firebase';
import { useAnalytics } from '../../hooks/useAnalytics';
import './CreatePost.css';

function CreatePost() {
  const navigate = useNavigate();
  const { categoryId } = useParams(); // Get categoryId from URL
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [hashtags, setHashtags] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId || '');
  const [categories, setCategories] = useState([]);
  const { logCustomEvent } = useAnalytics();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(firestore, 'categories');
        const categorySnapshot = await getDocs(categoriesCollection);
        const categoryList = categorySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name
        }));
        setCategories(categoryList);
        if (!selectedCategoryId && categoryList.length > 0) {
          setSelectedCategoryId(categoryList[0].id);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [selectedCategoryId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !content.trim() || !selectedCategoryId) {
      alert('Please fill in all required fields');
      return;
    }

    try {
      const hashtagArray = hashtags.split(',').map(tag => tag.trim().toLowerCase());

      const postData = {
        title,
        content,
        authorId: auth.currentUser.uid,
        createdAt: new Date(),
        hashtags: hashtagArray,
      };

      console.log('Saving post data:', postData);

      const categoryRef = collection(firestore, 'categories', selectedCategoryId, 'posts');
      const newPostRef = await addDoc(categoryRef, postData);

      console.log('New post created with ID:', newPostRef.id);

      logCustomEvent('create_post', { category: selectedCategoryId });

      navigate(`/category/${selectedCategoryId}`);
    } catch (error) {
      console.error('Error adding post: ', error);
      alert('Failed to create post. Please try again.');
    }
  };

  return (
    <div className="create-post-container">
      <div className="create-post-card">
        <h2 className="create-post-title">Create a New Post</h2>
        <form onSubmit={handleSubmit} className="create-post-form">
          <div className="form-group">
            <label htmlFor="category" className="form-label">Category</label>
            <select
              id="category"
              className="form-input"
              value={selectedCategoryId}
              onChange={(e) => setSelectedCategoryId(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="title" className="form-label">Title</label>
            <input
              type="text"
              id="title"
              className="form-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter your post title"
            />
          </div>
          <div className="form-group">
            <label htmlFor="content" className="form-label">Content</label>
            <textarea
              id="content"
              className="form-input form-textarea"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              placeholder="Write your post content here"
            />
          </div>
          <div className="form-group">
            <label htmlFor="hashtags" className="form-label">Hashtags</label>
            <input
              type="text"
              id="hashtags"
              className="form-input"
              value={hashtags}
              onChange={(e) => setHashtags(e.target.value)}
              placeholder="e.g., self-improvement, self-help, self-love (comma-separated)"
            />
          </div>
          <button type="submit" className="submit-button">Create Post</button>
        </form>
      </div>
    </div>
  );
}

export default CreatePost;

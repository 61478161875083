import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../../services/firebase';
import { AuthContext } from '../../contexts/AuthContext';
import './Stream.css';

function Stream() {
  const [stream, setStream] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { streamId } = useParams();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchStream = async () => {
      try {
        const streamDoc = await firestore.collection('streams').doc(streamId).get();
        if (streamDoc.exists) {
          setStream({ id: streamDoc.id, ...streamDoc.data() });
        } else {
          console.log('No such stream!');
        }
      } catch (error) {
        console.error('Error fetching stream:', error);
      }
    };

    const fetchMessages = () => {
      return firestore
        .collection('messages')
        .where('streamId', '==', streamId)
        .orderBy('createdAt')
        .onSnapshot((snapshot) => {
          const fetchedMessages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setMessages(fetchedMessages);
        });
    };

    fetchStream();
    const unsubscribe = fetchMessages();

    return () => unsubscribe();
  }, [streamId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('You must be logged in to send messages.');
      return;
    }
    if (newMessage.trim() === '') return;

    try {
      await firestore.collection('messages').add({
        streamId,
        content: newMessage,
        authorId: user.uid,
        createdAt: new Date()
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (!stream) return <div>Loading...</div>;

  return (
    <div className="stream">
      <div className="stream-info">
        <h1>{stream.title}</h1>
        <p>{stream.description}</p>
        <p>Category: {stream.category}</p>
        <p>Streamer: {stream.authorId}</p>
      </div>
      
      <div className="stream-video">
        {/* Placeholder for video stream */}
        <div className="video-placeholder">Video stream will appear here</div>
      </div>
      
      <div className="chat-section">
        <h2>Live Chat</h2>
        <div className="messages-list">
          {messages.map(message => (
            <div key={message.id} className="message">
              <span className="message-author">{message.authorId}: </span>
              <span className="message-content">{message.content}</span>
            </div>
          ))}
        </div>
        <form onSubmit={handleSendMessage}>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            required
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default Stream;


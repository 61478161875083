import React from 'react';
import './PageBanner.css';

function PageBanner({ tag, title, subtitle }) {
  return (
    <section className="page-banner">
      <div className="banner-content">
        <span className="section-tag">{tag}</span>
        <h1>{title}</h1>
        {subtitle && <p className="section-subtitle">{subtitle}</p>}
      </div>
      <div className="background-shapes">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
      </div>
    </section>
  );
}

export default PageBanner;

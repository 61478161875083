// This is a mock API service. In a real application, you would make actual API calls here.

export const getCategories = async () => {
    // Simulated API call
    return [
      { id: 1, name: 'ACE' },
      { id: 2, name: 'CFA' },
      { id: 3, name: 'Real Estate License' },
    ];
  };
  
  export const getPosts = async (categoryId) => {
    // Simulated API call
    return [
      { id: 1, title: 'Question about ACE exam', categoryId: 1 },
      { id: 2, title: 'CFA Level 1 study group', categoryId: 2 },
      { id: 3, title: 'Real Estate License requirements', categoryId: 3 },
    ];
  };
  
  export const getStreams = async () => {
    // Simulated API call
    return [
      { id: 1, title: 'Live Q&A for ACE exam' },
      { id: 2, title: 'CFA Level 2 problem solving' },
      { id: 3, title: 'Real Estate Market Analysis' },
    ];
  };
  
  export const bookSession = async (userId, date, time) => {
    // Simulated API call
    console.log(`Booked session with user ${userId} on ${date} at ${time}`);
    return true;
  };
import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  addDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  orderBy,
  onSnapshot,
  serverTimestamp,
  collectionGroup,
  limit,
  Timestamp,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore';
import { 
  getAuth, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBJSiFcZZ2mBXSj1D0mJ8pJsZa4tfwCOVM",
    authDomain: "mastermelon-certess.firebaseapp.com",
    projectId: "mastermelon-certess",
    storageBucket: "mastermelon-certess.appspot.com",
    messagingSenderId: "584578929031",
    appId: "1:584578929031:web:d2618aa7130845ef4fc322",
    measurementId: "G-CTE18BYW9R"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { 
  app,
  firestore,
  auth,
  analytics,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  orderBy,
  onSnapshot,
  serverTimestamp,
  collectionGroup,
  limit,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  logEvent,
  Timestamp,
  arrayUnion,
  arrayRemove
};

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firestore, collection, getDocs } from '../../services/firebase';
import { useAnalytics } from '../../hooks/useAnalytics';
import './CategoryList.css';

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(firestore, 'categories');
        const snapshot = await getDocs(categoriesCollection);
        const fetchedCategories = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(fetchedCategories);
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("Failed to load categories. Please try again later.");
      }
    };

    fetchCategories();
  }, []);

  if (error) return <div className="error">{error}</div>;

  const handleCategoryClick = (category) => {
    logUserAction('select_category', {
      category_id: category.id,
      category_name: category.name
    });
  };

  return (
    <div className="category-list-container">
      <h1 className="category-list-title">Explore Our Vibrant Communities</h1>
      {categories.length === 0 ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Discovering exciting categories...</p>
        </div>
      ) : (
        <div className="category-grid">
          {categories.map(category => (
            <Link to={`/category/${category.id}`} key={category.id} className="category-card" onClick={() => handleCategoryClick(category)}>
              <div className="category-content">
                <h2 className="category-name">{category.name}</h2>
                <p className="category-description">{category.description}</p>
              </div>
              <div className="category-arrow">
                <i className="fas fa-chevron-right"></i>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default CategoryList;

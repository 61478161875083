import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { firestore, auth, collection, query, getDocs, orderBy, doc, getDoc, where } from '../../services/firebase';
import LoadingSpinner from '../common/LoadingSpinner';
import PageBanner from '../common/PageBanner';
import './PostList.css';

function PostList() {
  const { categoryId } = useParams();
  const [posts, setPosts] = useState([]);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryAndPosts = async () => {
      try {
        // Fetch category name
        const categoryRef = doc(firestore, 'categories', categoryId);
        const categoryDoc = await getDoc(categoryRef);
        if (categoryDoc.exists()) {
          setCategoryName(categoryDoc.data().name);
        } else {
          setError("Category not found");
          return;
        }

        // Simplified query while waiting for index
        const postsCollectionRef = collection(firestore, 'categories', categoryId, 'posts');
        const q = query(
          postsCollectionRef,
          orderBy('lastActivityAt', 'desc'),
          orderBy('createdAt', 'desc')
        );
        const snapshot = await getDocs(q);

        const fetchedPosts = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const postData = docSnapshot.data();
          const repliesRef = collection(firestore, 'categories', categoryId, 'posts', docSnapshot.id, 'replies');
          const repliesSnapshot = await getDocs(repliesRef);
          const replyCount = repliesSnapshot.size;

          let authorName = 'Unknown';
          if (postData.authorId) {
            const userRef = doc(firestore, 'users', postData.authorId);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              authorName = userSnap.data().displayName || userSnap.data().email || 'Unknown';
            }
          }
          return {
            id: docSnapshot.id,
            ...postData,
            authorName,
            replyCount,
            createdAt: postData.createdAt?.toDate(),
            lastActivityAt: postData.lastActivityAt?.toDate() || postData.createdAt?.toDate(),
            hashtags: postData.hashtags || []
          };
        }));

        setPosts(fetchedPosts);

        // Fetch related events
        const eventsRef = collection(firestore, 'events');
        const eventsQuery = query(eventsRef, where('categoryId', '==', categoryId));
        const eventsSnapshot = await getDocs(eventsQuery);
        const fetchedEvents = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date?.toDate()
        }));
        setRelatedEvents(fetchedEvents);

      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load content. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryAndPosts();
  }, [categoryId]);

  const handleCreatePost = () => {
    if (!auth.currentUser) {
      alert("You must be logged in to create a post.");
      return;
    }
    navigate(`/category/${categoryId}/create-post`);
  };

  if (isLoading) return <LoadingSpinner message="Loading posts..." />;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="post-list-container">
      <PageBanner
        tag="Community Discussion"
        title={categoryName || 'Category'}
        subtitle="Join the conversation and share your insights with the community"
      />

      {/* Related Events Section */}
      {relatedEvents.length > 0 && (
        <div className="related-events-section">
          <h2>Related Events</h2>
          <div className="related-events-grid">
            {relatedEvents.map(event => (
              <Link to={`/events/${event.id}`} key={event.id} className="related-event-card">
                <h3>{event.name}</h3>
                <div className="event-details">
                  <span className="event-date">
                    <i className="far fa-calendar"></i>
                    {event.date?.toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      timeZoneName: 'short'
                    })}
                  </span>
                  <span className="event-duration">
                    <i className="far fa-clock"></i>
                    {event.duration}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}

      <button onClick={handleCreatePost} className="create-post-button">
        <i className="fas fa-plus"></i>
        Create New Post
      </button>

      {/* Existing Posts List */}
      {posts.length > 0 ? (
        <ul className="post-list">
          {posts.map(post => (
            <li key={post.id} className="post-item">
              <Link to={`/category/${categoryId}/post/${post.id}`} className="post-link">
                <h2 className="post-title">{post.title}</h2>
                <p className="post-excerpt">{post.content.substring(0, 100)}...</p>
                {post.hashtags.length > 0 && (
                  <div className="post-hashtags">
                    {post.hashtags.map((tag, index) => (
                      <span key={index}>#{tag}</span>
                    ))}
                  </div>
                )}
                <div className="post-meta">
                  <span>By {post.authorName}</span>
                  <span>{post.replyCount || 0} replies</span>
                  {post.hashtags && post.hashtags.length > 0 && (
                    <span className="hashtags">
                      {post.hashtags.map(tag => `#${tag}`).join(' ')}
                    </span>
                  )}
                </div>
                <div className="post-timestamps">
                  <span>Posted: {post.createdAt?.toLocaleString() || 'Unknown'}</span>
                  <span>Last activity: {post.lastActivityAt?.toLocaleString() || post.createdAt?.toLocaleString()}</span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-posts-message">No posts in this category yet. Be the first to create one!</p>
      )}
    </div>
  );
}

export default PostList;

import React from 'react';
import './LegalPages.css';

function CookiePolicy() {
  return (
    <div className="legal-container">
      <div className="legal-hero">
        <h1>Cookie Policy</h1>
        <p className="last-updated">Last updated: March 18, 2024</p>
      </div>

      <div className="legal-content">
        <div className="legal-nav">
          <div className="nav-header">Contents</div>
          <ul>
            <li><a href="#what">1. What Are Cookies</a></li>
            <li><a href="#types">2. Types of Cookies</a></li>
            <li><a href="#usage">3. How We Use Cookies</a></li>
            <li><a href="#control">4. Your Cookie Controls</a></li>
            <li><a href="#contact">5. Contact Us</a></li>
          </ul>
        </div>

        <div className="legal-sections">
          <section id="what">
            <h2>1. What Are Cookies</h2>
            <p>Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience by remembering your preferences and understanding how you use our site.</p>
          </section>

          <section id="types">
            <h2>2. Types of Cookies</h2>
            <div className="info-grid">
              <div className="info-card">
                <i className="fas fa-cog"></i>
                <h3>Essential Cookies</h3>
                <p>Required for basic website functionality</p>
              </div>
              <div className="info-card">
                <i className="fas fa-chart-line"></i>
                <h3>Analytics Cookies</h3>
                <p>Help us understand how visitors use our site</p>
              </div>
              <div className="info-card">
                <i className="fas fa-bullseye"></i>
                <h3>Marketing Cookies</h3>
                <p>Used to deliver relevant advertisements</p>
              </div>
            </div>
          </section>

          <section id="usage">
            <h2>3. How We Use Cookies</h2>
            <ul className="legal-list">
              <li>
                <i className="fas fa-check"></i>
                <span>To remember your preferences and settings</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To improve website performance and speed</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To analyze how our site is used</span>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <span>To provide personalized content and recommendations</span>
              </li>
            </ul>
          </section>

          <section id="control">
            <h2>4. Your Cookie Controls</h2>
            <p>You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed.</p>
            <div className="info-grid">
              <div className="info-card">
                <i className="fas fa-sliders-h"></i>
                <h3>Browser Settings</h3>
                <p>Adjust cookie settings in your browser preferences</p>
              </div>
              <div className="info-card">
                <i className="fas fa-toggle-on"></i>
                <h3>Cookie Consent</h3>
                <p>Manage your cookie preferences on our site</p>
              </div>
            </div>
          </section>

          <section id="contact">
            <h2>5. Contact Us</h2>
            <p>If you have any questions about our Cookie Policy, please contact us:</p>
            <div className="contact-info">
              <div className="contact-item">
                <i className="fas fa-envelope"></i>
                <p>Email: info@mastermelon.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
import React, { useState } from 'react';
import { firestore, collection, addDoc } from '../../services/firebase';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus('');

    try {
      await addDoc(collection(firestore, 'contact_messages'), {
        ...formData,
        createdAt: new Date(),
        status: 'new'
      });

      setStatus('success');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      setTimeout(() => setStatus(''), 5000);
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <section className="contact-hero">
        <h1>Contact Us</h1>
        <p className="hero-subtitle">We'd love to hear from you</p>
      </section>

      <div className="contact-content">
        <div className="contact-info">
          <div className="info-card">
            <i className="fas fa-envelope"></i>
            <h3>Email Us</h3>
            <p>info@mastermelon.com</p>
            {/* <p>business@mastermelon.com</p> */}
          </div>
          {/* <div className="info-card">
            <i className="fas fa-clock"></i>
            <h3>Business Hours</h3>
            <p>Monday - Friday: 9am - 6pm</p>
            <p>Weekend: 10am - 4pm</p>
          </div>
          <div className="info-card">
            <i className="fas fa-location-dot"></i>
            <h3>Location</h3>
            <p>123 Innovation Drive</p>
            <p>San Francisco, CA 94105</p>
          </div> */}
        </div>

        <div className="contact-form-container">
          <h2>Send us a Message</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your email address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="What is this about?"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Your message"
                rows="5"
              ></textarea>
            </div>

            {status && (
              <div className={`message ${status}`}>
                {status === 'success' && (
                  <span><i className="fas fa-check"></i> Message sent successfully!</span>
                )}
                {status === 'error' && (
                  <span><i className="fas fa-exclamation-circle"></i> Failed to send message. Please try again.</span>
                )}
              </div>
            )}

            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <><i className="fas fa-spinner fa-spin"></i> Sending...</>
              ) : (
                'Send Message'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase';
import { AuthContext } from '../../contexts/AuthContext';
import './CreateStream.css';

function CreateStream() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await firestore.collection('streams').add({
        title,
        description,
        category,
        streamerId: user.uid,
        createdAt: new Date(),
        status: 'upcoming'
      });
      navigate('/streams');
    } catch (error) {
      setError('Failed to create stream. Please try again.');
      console.error('Error creating stream:', error);
    }
  };

  return (
    <div className="create-stream">
      <h2>Create New Stream</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="category">Category:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select a category</option>
            <option value="ACE">ACE</option>
            <option value="CFA">CFA</option>
            <option value="Real Estate License">Real Estate License</option>
          </select>
        </div>
        <button type="submit">Start Stream</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
}

export default CreateStream;

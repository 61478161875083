import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { firestore, collection, addDoc } from '../../services/firebase';
import './Footer.css';

function Footer() {
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setSubscriptionStatus('Please enter your email');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSubscriptionStatus('Please enter a valid email');
      return;
    }

    setIsSubmitting(true);
    try {
      await addDoc(collection(firestore, 'newsletter_subscribers'), {
        email,
        subscribedAt: new Date(),
        source: 'footer'
      });

      setSubscriptionStatus('success');
      setEmail('');
      
      setTimeout(() => {
        setSubscriptionStatus('');
      }, 3000);

    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-grid">
          {/* Company Info */}
          <div className="footer-section">
            <h3>MasterMelon</h3>
            <p className="company-description">
              Empowering young professionals to grow, learn, and succeed together.
            </p>
            <div className="social-links">
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/events">Events</Link></li>
              <li><Link to="/community">Community</Link></li>
              <li><Link to="/marketplace">Marketplace</Link></li>
              {/* <li><Link to="/mentorship">Mentorship</Link></li> */}
            </ul>
          </div>

          {/* Company */}
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              {/* <li><Link to="/careers">Careers</Link></li>
              <li><Link to="/partners">Partners</Link></li> */}
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="footer-section newsletter">
            <h4>Stay Updated</h4>
            <p>Get the latest insights and updates delivered to your inbox.</p>
            <form className="newsletter-form" onSubmit={handleSubscribe}>
              <div className="input-wrapper">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isSubmitting}
                />
                <button 
                  type="submit" 
                  disabled={isSubmitting}
                  className={isSubmitting ? 'submitting' : ''}
                >
                  {isSubmitting ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    'Subscribe'
                  )}
                </button>
              </div>
              {subscriptionStatus && (
                <div className={`subscription-message ${subscriptionStatus}`}>
                  {subscriptionStatus === 'success' && (
                    <span><i className="fas fa-check"></i> Thanks for subscribing!</span>
                  )}
                  {subscriptionStatus === 'error' && (
                    <span><i className="fas fa-exclamation-circle"></i> Something went wrong. Please try again.</span>
                  )}
                  {subscriptionStatus !== 'success' && subscriptionStatus !== 'error' && (
                    <span>{subscriptionStatus}</span>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="footer-bottom">
          <div className="footer-legal">
            <span>© {new Date().getFullYear()} MasterMelon. All rights reserved.</span>
            <div className="legal-links">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms">Terms of Service</Link>
              <Link to="/cookies">Cookie Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

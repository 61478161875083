import { useEffect, useCallback } from 'react';
import { analytics, logEvent } from '../services/firebase';

export const useAnalytics = () => {
  // Log page views
  const logPageView = useCallback(() => {
    logEvent(analytics, 'page_view', {
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_title: document.title,
      timestamp: new Date().toISOString()
    });
  }, []);

  // Track initial page load and navigation
  useEffect(() => {
    // Log initial page load
    logEvent(analytics, 'first_visit', {
      page_path: window.location.pathname,
      referrer: document.referrer,
      timestamp: new Date().toISOString()
    });

    // Log initial page view
    logPageView();

    // Set up listener for route changes
    const handleRouteChange = () => {
      logPageView();
    };

    window.addEventListener('popstate', handleRouteChange);
    
    // Cleanup listener
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [logPageView]);

  // Generic event logger - add category for better organization
  const logCustomEvent = useCallback((eventName, eventParams = {}) => {
    logEvent(analytics, eventName, {
      event_category: eventParams.category || 'general',
      ...eventParams,
      timestamp: new Date().toISOString()
    });
  }, []);

  // Predefined event loggers
  const logButtonClick = useCallback((buttonName, componentName) => {
    logCustomEvent('interaction_button_click', {
      category: 'user_interaction',
      button_name: buttonName,
      component_name: componentName
    });
  }, [logCustomEvent]);

  const logFormSubmission = useCallback((formName, success) => {
    logCustomEvent('form_interaction_submit', {
      category: 'form_interaction',
      form_name: formName,
      success: success
    });
  }, [logCustomEvent]);

  const logUserAction = useCallback((actionName, actionDetails = {}) => {
    logCustomEvent('user_action', {
      action_name: actionName,
      ...actionDetails
    });
  }, [logCustomEvent]);

  const logError = useCallback((errorType, errorMessage, componentName) => {
    logCustomEvent('error_client_side', {
      category: 'error',
      error_type: errorType,
      error_message: errorMessage,
      component_name: componentName
    });
  }, [logCustomEvent]);

  const logSearch = useCallback((searchTerm, category = 'search') => {
    logCustomEvent('search_query', {
      category: category,
      search_term: searchTerm
    });
  }, [logCustomEvent]);

  const logNavigation = useCallback((fromPath, toPath) => {
    logCustomEvent('navigation_page_change', {
      category: 'navigation',
      from_path: fromPath,
      to_path: toPath
    });
  }, [logCustomEvent]);

  const logFeatureUse = useCallback((featureName, featureData = {}) => {
    logCustomEvent(`feature_${featureName}`, {
      category: 'feature',
      feature_name: featureName,
      ...featureData
    });
  }, [logCustomEvent]);
  

  return {
    logCustomEvent,
    logButtonClick,
    logFormSubmission,
    logUserAction,
    logError,
    logSearch,
    logNavigation,
    logFeatureUse
  };
};

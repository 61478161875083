import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../services/firebase';
import { signOut } from 'firebase/auth';
import './Navigation.css';
import logo from '../../assets/images/logo.png';
import { useAnalytics } from '../../hooks/useAnalytics';

function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(auth.currentUser);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { logUserAction } = useAnalytics();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      unsubscribe();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
      setIsMobileMenuOpen(false);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleNavClick = (linkName) => {
    logUserAction('navigation_click', {
      link_name: linkName,
      previous_path: window.location.pathname
    });
  };

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="MasterMelon" className="logo-image" />
          <span className="site-name">MasterMelon</span>
        </Link>

        <div className={`nav-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <div className="nav-links">
            <Link 
              to="/" 
              onClick={() => handleNavClick('home')}
              className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
            >
              Home
            </Link>
            <Link 
              to="/community" 
              onClick={() => handleNavClick('community')}
              className={`nav-link ${location.pathname === '/community' ? 'active' : ''}`}
            >
              Community
            </Link>
            <Link 
              to="/events" 
              onClick={() => handleNavClick('events')}
              className={`nav-link ${location.pathname === '/events' ? 'active' : ''}`}
            >
              Events
            </Link>
            <Link 
              to="/marketplace" 
              onClick={() => handleNavClick('marketplace')}
              className={`nav-link ${location.pathname === '/marketplace' ? 'active' : ''}`}
            >
              Marketplace
            </Link>
            <Link 
              to="/about" 
              onClick={() => handleNavClick('about')}
              className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}
            >
              About
            </Link>
          </div>

          <div className="nav-actions">
            {user ? (
              <>
                <div className="user-dropdown">
                  <button className="user-button">
                    <div className="user-avatar">
                      {user.photoURL ? (
                        <img src={user.photoURL} alt="Profile" />
                      ) : (
                        <span>{user.email?.[0].toUpperCase()}</span>
                      )}
                    </div>
                    <i className="fas fa-chevron-down"></i>
                  </button>
                  <div className="dropdown-content">
                    <Link to="/profile">Profile</Link>
                    {/* <Link to="/settings">Settings</Link> */}
                    <button onClick={handleLogout} className="logout-button">
                      Logout
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="auth-buttons">
                <Link to="/login" className="login-button">Log In</Link>
                <Link to="/signup" className="signup-button">Sign Up</Link>
              </div>
            )}
          </div>
        </div>

        <button 
          className={`mobile-menu-button ${isMobileMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </nav>
  );
}

export default Navigation;
